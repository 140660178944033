const tableData = [
  {
    id: 1,
    course: 'Recruiting Mantotalname',
    total_reviews: 10,
  }, {
    id: 2,
    course: 'Quality Engineer',
    total_reviews: 23,
  }, {
    id: 3,
    course: 'Senior Editor',
    total_reviews: 42,
  }, {
    id: 4,
    course: 'Programmer II',
    total_reviews: 15,
  },
  {
    id: 5,
    course: 'Data Coordiator',
    total_reviews:  17,
  }, {
    id: 6,
    course: 'Data Coordiator',
    total_reviews: 2,
  },
]

export { tableData };
